import { isMatch } from 'date-fns';

import { BOOKING_FUNNEL_MAX, BOOKING_FUNNEL_MIN } from './constants';
import { newUTCToday } from './date';

export function isDateValid(date: string | undefined): boolean {
  return date !== undefined && isMatch(date, 'yyyy-mm-dd') && date > newUTCToday();
}

export function isGroupSizeValid(groupSize: string | undefined): boolean {
  return groupSize !== undefined && Number(groupSize) >= BOOKING_FUNNEL_MIN && Number(groupSize) <= BOOKING_FUNNEL_MAX;
}
