import { defineStore } from 'pinia';
import { LocationQuery } from 'vue-router';

import type { Restaurant, SearchFilterInput, SearchFilterResponse } from '~/graphql';

import { INCLUDE_FILTERS_KEYS } from '~/core/constants';
import { cleanUnusedQueryParams, parseQueryParams } from '~/helpers/urlQueryParams';

const defaultFilterInput: SearchFilterInput = {
  AVERAGE_RATING: [],
  FOOD_PREFERENCES: [],
  OFFER_AND_LOYALTY: [],
  STYLE_OF_FOOD: [],
  THEMATIC: [],
  available: false,
  includeFilters: true,
  maxPrice: undefined,
  minPrice: undefined
};

export const searchRestaurantStore = defineStore('restaurant-store', () => {
  const route = useRoute();
  const router = useRouter();

  const currentQueryParams = ref(route.query);

  const inputStatus = ref<{ state: boolean }>({
    state: false
  });

  const filterData = ref<SearchFilterResponse>();

  const filterInput = reactive<SearchFilterInput>({ ...defaultFilterInput });

  const initialFilterInput = computed({
    get: () => cleanUnusedQueryParams(parseQueryParams(route.query), INCLUDE_FILTERS_KEYS) as SearchFilterInput,
    set: (value: {} | SearchFilterInput) => value
  });
  const updatedRestaurantsResults = ref<Restaurant[]>();

  const numberOfSelectedFilters = ref<number>(0);

  const dataComputed = computed(() => filterData);

  const displayCriteria = computed(() => {
    return filterData?.value?.THEMATIC?.length || filterData?.value?.STYLE_OF_FOOD?.length || filterData?.value?.FOOD_PREFERENCES?.length;
  });

  const numberRestaurantsResults = computed(() => updatedRestaurantsResults.value?.length);

  const displayErrorMessage = computed(() => {
    return (
      !numberRestaurantsResults &&
      (filterData?.value?.globalMinPrice !== (filterData?.value?.userMinPrice || filterData?.value?.globalMinPrice) ||
        filterData?.value?.globalMaxPrice !== (filterData?.value?.userMaxPrice || filterData?.value?.globalMaxPrice))
    );
  });

  const calcNumberOfSelectedFilters = () => {
    const query = parseQueryParams(currentQueryParams.value);
    const gMaxPrice = dataComputed.value?.value?.globalMaxPrice as number;
    const gMinPrice = dataComputed.value?.value?.globalMaxPrice as number;
    const available = query.available ? 1 : 0;
    const thematicL = query.THEMATIC?.length ?? 0;
    const styleOfFoodL = query.STYLE_OF_FOOD?.length ?? 0;
    const foodPreferencesL = query.FOOD_PREFERENCES?.length ?? 0;

    const fMaxPrice = Number(query.maxPrice);
    const fMinPrice = Number(query.minPrice);

    const range = gMinPrice < fMinPrice || gMaxPrice > fMaxPrice ? 1 : 0;

    const rating = query.AVERAGE_RATING?.length ?? 0;
    const offerLoyalty = query.OFFER_AND_LOYALTY?.length ?? 0;

    numberOfSelectedFilters.value = thematicL + styleOfFoodL + foodPreferencesL + range + available + rating + offerLoyalty;
  };

  const updateFilterData = (newData: SearchFilterResponse) => {
    filterData.value = newData;
  };

  const updateInputStatus = (status: boolean) => {
    inputStatus.value.state = status;
  };

  const updateFilterInput = (newInput?: SearchFilterInput, force?: boolean, defaultValues?: boolean) => {
    Object.assign(filterInput, defaultValues ? { ...defaultFilterInput, ...newInput } : newInput ?? {});

    if (force) {
      filterInput.minPrice = filterData?.value?.globalMinPrice;
      filterInput.maxPrice = filterData?.value?.globalMaxPrice;
    }
  };

  const setInitialFilterInput = (newInput?: SearchFilterInput) => {
    initialFilterInput.value = newInput ?? { ...defaultFilterInput };
  };

  const updateRestaurantsResults = (newRestaurants: Restaurant[]) => {
    updatedRestaurantsResults.value = newRestaurants;
  };

  const getUpdatedRestaurantsResults = () => {
    return updatedRestaurantsResults.value;
  };

  const getCurrentQueryParams = () => {
    return parseQueryParams(currentQueryParams.value);
  };

  const updateCurrentQueryParams = (query: Record<string, any>) => {
    currentQueryParams.value = query;
  };

  const handleInitialFilterDataInput = () => {
    const route = useRoute();
    cleanFilterInput();
    const object = {
      ...(cleanUnusedQueryParams(parseQueryParams(route.query), INCLUDE_FILTERS_KEYS) as SearchFilterInput)
    } as SearchFilterInput;

    if ((filterData?.value?.globalMaxPrice || filterData?.value?.userMaxPrice) && !object.maxPrice) {
      object.maxPrice = filterData?.value?.globalMaxPrice;
    }

    if ((filterData?.value?.globalMinPrice || filterData?.value?.userMinPrice) && !object.minPrice) {
      object.minPrice = filterData?.value.globalMinPrice;
    }
    updateFilterInput(object);
  };

  const submitFilterInput = () => {
    const query = {
      ...router.currentRoute.value.query,
      ...filterInput
    } as unknown as LocationQuery;

    currentQueryParams.value = cleanUnusedQueryParams(query) as LocationQuery;
    calcNumberOfSelectedFilters();
    router.push({ query });
  };

  const cleanFilterInput = () => {
    const newObj = { ...filterInput } as Record<string, any>;
    Object.keys(newObj).forEach((key) => {
      const filterKey = key as keyof typeof filterInput;
      if (newObj[filterKey] !== undefined && defaultFilterInput[filterKey] !== undefined) {
        newObj[filterKey] = Array.isArray(newObj[filterKey]) ? [] : defaultFilterInput[filterKey];
      }
    });
    updateFilterInput(newObj as SearchFilterInput, true);
  };

  const reset = () => {
    router.push({ query: {} });
    cleanFilterInput();
    setInitialFilterInput();
    inputStatus.value.state = false;
    updatedRestaurantsResults.value = undefined;
    currentQueryParams.value = {};
    numberOfSelectedFilters.value = 0;
  };

  return {
    calcNumberOfSelectedFilters,
    currentQueryParams,
    dataComputed,
    displayCriteria,
    displayErrorMessage,
    filterData,
    filterInput,
    getCurrentQueryParams,
    getUpdatedRestaurantsResults,
    handleInitialFilterDataInput,
    initialFilterInput,
    inputStatus,
    numberOfSelectedFilters,
    numberRestaurantsResults,
    reset,
    setInitialFilterInput,
    submitFilterInput,
    updateCurrentQueryParams,
    updateFilterData,
    updateFilterInput,
    updateInputStatus,
    updateRestaurantsResults
  };
});
