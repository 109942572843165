export const useScrollToContainer = (
  el: string,
  container?: string | undefined,
  scrollPosition: ScrollLogicalPosition = 'start',
  offset: number = 0
) => {
  const element = document.getElementById(el);

  if (scrollPosition && offset === 0) {
    element?.scrollIntoView({ behavior: 'smooth', block: scrollPosition });
  } else if (container && element) {
    const containerElement = document.getElementById(container);
    if (containerElement) {
      const elementTop = element.getBoundingClientRect().top;
      const containerTop = containerElement.getBoundingClientRect().top;
      const relativeY = elementTop - containerTop + containerElement.scrollTop - offset;
      containerElement.scrollTo({ behavior: 'smooth', top: relativeY });
    }
  } else if (element) {
    const y = element.getBoundingClientRect().top + window.scrollY;
    window.scrollTo({ behavior: 'smooth', top: y - offset });
  }
};
