<template>
  <button :aria-controls="hasModal ? id : undefined" :class="`Average-price--${variant}`" @click="averagePriceAction" class="Average-price">
    <ux-atoms-typo :aria-label="ariaLabel" :variant="text" as="span" color="light"> ± {{ price }}{{ currency }}*</ux-atoms-typo>
  </button>
  <Teleport to="#teleports" v-if="hasModal">
    <ux-molecules-modal
      :event-element="modalTarget"
      :id="id"
      button-no-border
      button-position="right"
      has-label-id
      size="small"
      v-if="hasModal"
      v-model="averagePriceInformationModalIsOpen"
    >
      <template #header>
        <ux-atoms-typo :text="$t('ux.atoms.averagePrice.averagePrice')" as="p" color="grey-1" text-align="center" variant="text-heading-01" />
      </template>
      <ux-atoms-typo :text="$t('ux.atoms.averagePrice.averagePriceInformation')" as="p" color="grey-1" text-align="center" variant="text-small" />
    </ux-molecules-modal>
  </Teleport>
</template>
<script setup lang="ts">
import type { TypoVariant } from '~/components/ux/atoms/Typo/types';

import { useScrollToContainer } from '~/helpers/scrollToContainer';

export interface AveragePriceProps {
  currency: string;
  hasModal?: boolean;
  price: number;
  text?: TypoVariant;
  variant?: 'CardRestaurant' | 'HeroRestaurant';
}

const { t } = useI18n();
const id = useId();

const ariaLabel = computed<string>(() => {
  return `${t('ux.atoms.averagePrice.averagePrice')} ${props.price} ${props.currency}`;
});

const props = withDefaults(defineProps<AveragePriceProps>(), {
  currency: 'EUR',
  hasModal: false,
  price: 0,
  text: 'text-small',
  variant: 'HeroRestaurant'
});

const modalTarget = ref<HTMLElement | null>(null);

const averagePriceAction = (event: Event) => {
  if (props.hasModal) {
    toggleAveragePriceInformationModal(event?.target as HTMLElement);
  } else {
    useScrollToContainer('#');
  }
};

const averagePriceInformationModalIsOpen = ref<boolean>(false);

const toggleAveragePriceInformationModal = (targetElement: HTMLElement) => {
  modalTarget.value = targetElement;
  averagePriceInformationModalIsOpen.value = !averagePriceInformationModalIsOpen.value;
};
</script>
<style lang="scss" scoped>
@use 'AveragePrice';
</style>
