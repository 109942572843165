<template>
  <div :class="['Tag', variantClass, variantPaddingClass]">
    <ux-atoms-icon :name="variantIcon" class="Tag__icon" v-if="variantIcon" />
    <ux-atoms-typo :text="String(labelText)" as="span" class="Tag__text" v-if="labelText" variant="text-xsmall" />
  </div>
</template>
<script setup lang="ts">
import { IconName } from '../Icon/types';

export interface TagProps {
  icon?: string;
  label?: number | string;
  paddingSize?: 'medium' | 'small';
  variant?: 'ap' | 'enrolment' | 'no-availability' | 'offer';
}

const props = withDefaults(defineProps<TagProps>(), {
  icon: undefined,
  label: undefined,
  paddingSize: undefined,
  variant: undefined
});

const { t } = useI18n();
const variantClass = computed(() => {
  return `Tag--${props.variant}`;
});

const variantPaddingClass = computed(() => {
  return props.paddingSize ? `Tag--padding-${props.paddingSize}` : '';
});

const labelText = computed(() => {
  if (props.variant === 'offer') {
    return t('ux.atoms.tag.offersAvailable');
  }

  return props.label ?? '';
});

const variantIcon = computed<IconName | undefined>(() => {
  if (props.variant && props.variant === 'offer') {
    return props.variant as IconName;
  }

  return props.icon as IconName | undefined;
});
</script>
<style lang="scss" scoped>
@use 'Tag.scss';
</style>
