import { INCLUDE_FILTERS_KEYS } from '~/core/constants';
import {
  NextAvailabilityQuery,
  NextAvailabilityQueryVariables,
  Restaurant,
  SearchFilterInput,
  SearchFilterResponse,
  useNextAvailabilityLazyQuery,
  useSearchRestaurantsLazyQuery
} from '~/graphql';
import { BOOKING_FUNNEL_DEFAULT } from '~/helpers/constants';
import { newUTCToday } from '~/helpers/date';
import { isDateValid, isGroupSizeValid } from '~/helpers/urlParam';
import { cleanUnusedQueryParams, parseQueryParams } from '~/helpers/urlQueryParams';

const nextAvailabilityLoading = ref(false);
const result = ref<NextAvailabilityQuery['nextAvailability']>();

export function useSearchRestaurant(query?: Record<string, any>, searchFilter?: SearchFilterInput) {
  const route = useRoute();

  const searchParams = computed(() => {
    const dateParam = route.query.date as string;
    const groupSizeParam = route.query.guests as string;
    const slot = route.query.slot as string | undefined;

    return {
      date: dateParam && isDateValid(dateParam) ? dateParam : newUTCToday(),
      groupSize: isGroupSizeValid(groupSizeParam) ? Number(groupSizeParam) : BOOKING_FUNNEL_DEFAULT,
      slot
    };
  });

  const { setInitialFilterInput, updateCurrentQueryParams, updateFilterData, updateInputStatus, updateRestaurantsResults } = searchRestaurantStore();

  if (searchFilter) {
    setInitialFilterInput(searchFilter);
  }

  const nextAvaibilityQuery = async (variables: NextAvailabilityQueryVariables) => {
    nextAvailabilityLoading.value = true;

    const {
      load,
      onError,
      onResult,
      result: data
    } = useNextAvailabilityLazyQuery({
      from: searchParams.value.date,
      groupSize: searchParams.value.groupSize,
      restaurantId: route.params.restaurantCode as string
    });

    onResult((response) => {
      if (response.loading) return;

      if (response.data) {
        result.value = response.data.nextAvailability;
      }

      nextAvailabilityLoading.value = false;
    });

    onError(() => {
      nextAvailabilityLoading.value = false;
    });

    await load(undefined, variables);

    return data.value;
  };

  const handleFilterChange = (filterInput: SearchFilterInput) => {
    const { load, onResult } = useSearchRestaurantsLazyQuery({
      ...query,
      date: searchParams.value.date,
      groupSize: searchParams.value.groupSize,
      searchFilters: { ...filterInput, includeFilters: true }
    });

    onResult((response) => {
      if (response.loading) return;
      // store new restaurants data
      updateRestaurantsResults(response.data.searchRestaurants?.results as Restaurant[]);
      // store new filter data
      updateFilterData(response.data.searchRestaurants?.filters as SearchFilterResponse);
      // update input status
      updateInputStatus(false);
    });

    load();
  };

  const initialFilterInput = () => {
    const { query } = useRoute();
    const initialFilters = parseQueryParams(query);
    updateCurrentQueryParams(initialFilters);

    return cleanUnusedQueryParams(initialFilters, INCLUDE_FILTERS_KEYS);
  };

  return {
    handleFilterChange,
    initialFilterInput,
    nextAvaibilityQuery,
    nextAvailability: result,
    nextAvailabilityLoading,
    searchParams
  };
}
