export const formatPrice = (price: null | number) => {
  if (!price) {
    return null;
  }

  return parseFloat(price.toFixed(2));
};

export const formatStarsIconArray = (stars: number, maxRating = 5): string[] => {
  const starsArray: string[] = [];
  const filledStars = Math.floor(stars);
  const completeStars = maxRating - Math.ceil(stars);

  for (let i = 0; i < filledStars; i++) {
    starsArray.push('star-larger');
  }

  if (stars % 1 !== 0) {
    starsArray.push('star-larger-half');
  }

  if (completeStars !== 0) {
    for (let i = 0; i < completeStars; i++) {
      starsArray.push('star-larger-outfilled');
    }
  }

  return starsArray;
};
