<template>
  <div :class="['Rating', `Rating--${variant}`]" v-if="stars">
    <div class="Rating__stars">
      <ux-atoms-typo
        :class="['Rating__stars-text', { 'Rating__stars-text--block': isBlock }]"
        :color="typoColor"
        :text="stars.toString()"
        :variant="textVariant"
        as="p"
        v-if="variant !== RatingVariant.OnlyStars && variant !== RatingVariant.Filter"
      />
      <ux-atoms-icon class="Rating__stars-icon" name="star" size="m" v-if="isOneStar" />
      <div class="Rating__stars-full" v-else>
        <div :key="value" v-for="value of formatedStarsArray">
          <ux-atoms-icon
            :class="[{ 'Rating__stars-icon--block-alt': isBlock }, 'Rating__stars-icon-' + value]"
            :name="iconValue(value) as IconName"
            :size="iconSize"
            class="Rating__stars-icon"
          />
        </div>
      </div>
    </div>
    <div :class="{ 'Rating__comments-block': isBlock }" class="Rating__comments" v-if="comments">
      <ux-atoms-typo :color="isBlock ? 'dark' : 'light'" :text="commentsText" :variant="commentsTextVariant" as="p" />
    </div>
    <span class="accessibility-sr-only">
      {{ accessText }}
    </span>
  </div>
</template>
<script setup lang="ts">
import { IconName } from '~/components/ux/atoms/Icon/types';
import { RatingVariant } from '~/core/types';
import { formatStarsIconArray } from '~/helpers/format';

export interface LinkProps {
  comments?: number;
  stars: number;
  variant?: RatingVariant;
}

const props = withDefaults(defineProps<LinkProps>(), {
  comments: 0,
  stars: 0,
  variant: RatingVariant.OneStar
});

const { t } = useI18n();

const commentsText = computed(() => {
  return isBlock.value ? `${props.comments} ${t('ux.organismes.customersReviews.countReviews')}` : `(${props.comments})`;
});

const accessText = computed(() => {
  return `${t('ux.atoms.rating.srStars', { stars: props.stars })}${props.comments ? t('ux.atoms.rating.srComments', { comments: props.comments }) : ''}`;
});

const typoColor = computed(() => (props.variant === 'full' ? 'dark' : 'inherit'));
const iconSize = computed(() => (props.variant === RatingVariant.Filter ? 'm' : 's'));

const formatedStarsArray = computed(() => formatStarsIconArray(props.stars));

const isOneStar = computed(() => props.variant === RatingVariant.OneStar);

const isBlock = computed(() => props.variant === RatingVariant.Block);

const textVariant = computed(() => {
  switch (props.variant) {
    case RatingVariant.Block:
      return 'expressive-heading-04';
    case RatingVariant.Full:
    case RatingVariant.OnlyStars:
      return 'text-regular';
    case RatingVariant.OneStar:
      return 'text-small';
  }
});

const commentsTextVariant = computed(() => {
  switch (props.variant) {
    case RatingVariant.OnlyStars:
    case RatingVariant.Full:
      return 'text-regular';
    case RatingVariant.Block:
    case RatingVariant.OneStar:
      return 'text-small';
  }
});

const iconValue = (value: string) => {
  return value === 'star-larger-outfilled' ? 'star-larger' : value;
};
</script>
<style lang="scss" scoped>
@use 'Rating.scss';
</style>
